<template>
  <div
    class="VModalChoosingWaiter"
    :class="{ 'VModalChoosingWaiter--selected': filter.waiter }"
  >
    <div class="VModalChoosingWaiter__header">
      <div class="VModalChoosingWaiter__name">
        Официант
      </div>
      <div
        class="VModalChoosingWaiter__closeModal"
        @click="$emit('closeModal')"
      />
    </div>
    <div class="VModalChoosingWaiter__search">
      <input
        v-model="search"
        type="text"
        placeholder="Введите имя сотрудника.."
      >
      <button
        v-if="filter.waiter"
        class="VModalChoosingWaiter__search__reset"
        @click="onChoiceWaiter('')"
      >
        Сбросить
      </button>
      <div class="VModalChoosingWaiter__search__icon">
        <VIconSearch />
      </div>
    </div>
    <div class="VModalChoosingWaiter__list">
      <div
        v-for="item in sortedWaiters"
        :key="item.id"
        class="VModalChoosingWaiter__list__item"
        :class="{
          VModalChoosingWaiter__list__item__active:
            item.user_id === filter.waiter,
        }"
        @click="onChoiceWaiter(item.user_id)"
      >
        {{ item.display_name ? item.display_name : item.name }}
      </div>

      <div
        v-if="!sortedWaiters.length"
        class="VModalRestaurant__empty-label"
      >
        Ничего не найдено
      </div>
    </div>
  </div>
</template>

<script>
import VIconSearch from '@/assets/images/icons/v-icon-search'
export default {
  name: 'VModalChoosingWaiter',

  components: {
    VIconSearch,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    waiters: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['closeModal', 'updateFilter'],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    sortedWaiters() {
      const result = []

      if (this.search && this.waiters) {
        for (const waitersKey in this.waiters) {
          if (this.waiters[waitersKey].user_id) {
            const name = this.waiters[waitersKey]?.name || ''
            const displayName = this.waiters[waitersKey]?.display_name || ''

            if (
              name.toLowerCase().includes(this.search.toLowerCase()) ||
              displayName.toLowerCase().includes(this.search.toLowerCase())
            ) {
              if (this.waiters[waitersKey].user_id)
                result.push(this.waiters[waitersKey])
            }
          }
        }
      } else if (this.waiters) {
        for (const waitersKey in this.waiters) {
          if (this.waiters[waitersKey].user_id) {
            result.push(this.waiters[waitersKey])
          }
        }
      }

      return result
    },
  },

  methods: {
    onChoiceWaiter(value) {
      this.$emit('updateFilter', { name: 'waiter', value })
      this.$emit('closeModal')
    },
  },
}
</script>
<style lang="scss">
.VModalChoosingWaiter {
  width: 327px;
  height: 356px;
  border: 1px solid #e6e7eb;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  position: absolute;
  bottom: -364px;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 16px;
  &--selected {
    height: 396px;
    bottom: -404px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }
  &__closeModal {
    width: 24px;
    height: 24px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      border-radius: 5px;
    }
    &:after {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(-45deg);
      border-radius: 5px;
    }
  }
  &__search {
    position: relative;
    margin-bottom: 12px;
    input {
      width: 100%;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      padding: 0 14px 0 36px;
      color: #80869a;
      &:focus {
        outline: 1px solid #6764ff;
      }
    }
    &__reset {
      cursor: pointer;
      width: 100%;
      height: 30px;
      margin-top: 12px;
      border: 1px solid #ec4e4e;
      border-radius: 6px;
      background: rgba(254, 195, 195, 0.15);
      color: #ec4e4e;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    &__icon {
      position: absolute;
      left: 13px;
      top: 7px;
    }
  }
  &__list {
    overflow: auto;
    height: 238px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #f7f8fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #6764ff;
      border-radius: 2px;
    }

    &__item {
      width: 280px;
      padding: 8px 12px;
      margin-bottom: 8px;
      background: #f7f8fa;
      border-radius: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #80869a;
      &__active {
        background: #f8f9fe;
        color: #010d35;
      }
    }
  }

  &__empty-label {
    text-align: center;
    color: #80869a;
  }
}
</style>
