<template>
  <div class="vTimePeriod">
    <div
      class="vTimePeriod__switcher"
      @click="useFilter('DAY')"
    >
      <label
        for="day"
        class="vTimePeriod__switcher-label"
        :class="{ active: filter.timePeriod === 'DAY' }"
      >
        Сегодня
      </label>
    </div>
    <div
      class="vTimePeriod__switcher"
      @click="useFilter('MONTH')"
    >
      <label
        for="month"
        class="vTimePeriod__switcher-label"
        :class="{ active: filter.timePeriod === 'MONTH' }"
      >
        Месяц
      </label>
    </div>
    <div class="vTimePeriod__period">
      <div
        class="vTimePeriod__period-label"
        :class="{ active: period }"
      >
        {{ period ? convertUTC : 'Выбрать период' }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <date-picker
        v-model:value="period"
        :lang="langString"
        :disabled-date="disabledAfterToday"
        range
        @closed="blur"
      />
    </div>
    <vLightPreloader v-if="preloader" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import '@/utils/localeDatePicker'
import { filterWeak, filterMonth, filterYear } from '@/utils/filterDate'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VTimePeriod',
  components: {
    DatePicker,
    vLightPreloader,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['selectFilter', 'filterTransaction', 'updateFilter'],
  data() {
    return {
      period: null,
      langString: 'ru',
      preloader: false,
      dropdown: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    convertUTC() {
      if (this.period) {
        let periodFrom = this.period[0].toLocaleString().split(',')
        periodFrom = periodFrom[0]

        let periodTo = this.period[1].toLocaleString().split(',')
        periodTo = periodTo[0]

        return periodFrom === periodTo
          ? periodFrom
          : periodFrom + ' - ' + periodTo
      }
      return ''
    },
  },
  watch: {
    async period() {
      if (this.period) {
        const startDate = new Date(this.period[0])
        const yearStart = startDate.getFullYear()
        const monthStart =
          parseInt(startDate.getMonth() + 1) < 10
            ? '0' + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        const dayStart =
          parseInt(startDate.getDate()) < 10
            ? '0' + startDate.getDate()
            : startDate.getDate()
        const newStartDateArr = `${yearStart}-${monthStart}-${dayStart}`

        const endDate = new Date(this.period[1])
        const yearEnd = endDate.getFullYear()
        const monthEnd =
          parseInt(endDate.getMonth() + 1) < 10
            ? '0' + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        const dayEnd =
          parseInt(endDate.getDate()) < 10
            ? '0' + endDate.getDate()
            : endDate.getDate()
        const newEndDateArr = `${yearEnd}-${monthEnd}-${dayEnd}`

        this.$emit('updateFilter', {
          name: 'timePeriod',
          value: 'CUSTOM_PERIOD',
        })
        this.$emit('updateFilter', {
          name: 'periodFrom',
          value: newStartDateArr,
        })
        this.$emit('updateFilter', {
          name: 'periodBefore',
          value: newEndDateArr,
        })
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
    document
      .querySelectorAll('.vTimePeriod__period input')
      .forEach((e) => (e.readOnly = true))
  },
  unmounted() {
    document.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    ...mapActions('transactions', ['FETCH_TRANSACTIONS']),
    blur() {
      document.querySelector(':focus').blur()
    },
    disabledAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today < date
    },
    selectSort(idx, status) {
      const data = {
        idx,
        status,
      }
      // const filterArr = this.sortList.filter(i => i.active)
      this.$emit('filterTransaction', data)
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
    },
    closeDropdown() {
      this.dropdown = false
    },
    async useFilter(value) {
      const newPeriod = value === this.filter.timePeriod ? '' : value

      this.$emit('updateFilter', { name: 'periodFrom', value: '' })
      this.$emit('updateFilter', { name: 'periodBefore', value: '' })
      this.$emit('updateFilter', { name: 'timePeriod', value: newPeriod })
      this.period = null
    },
  },
}
</script>
<style lang="scss" scoped>
.vTimePeriod {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 300px;
  }
  &__switcher {
    display: flex;
    align-items: center;
    position: relative;
    &-label {
      cursor: pointer;
      padding: 6px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
      }
    }
  }
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    &-label {
      cursor: pointer;
      padding: 2px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      //margin-right: 12px;
      display: flex;
      align-items: center;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
        svg {
          stroke: #fff;
        }
      }
    }
    svg {
      stroke: #80869a;
    }
  }
}
.mx-datepicker.mx-datepicker-range {
  cursor: pointer;
  position: absolute;
  left: -160px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  input {
    cursor: pointer;
  }
}
.mx-calendar-range {
  .mx-calendar:last-child {
    display: none;
  }
}
.mx-datepicker-main.mx-datepicker-popup {
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  width: 375px;
  box-shadow: none;
}
.mx-btn.mx-btn-text.mx-btn-current-year,
.mx-btn.mx-btn-text.mx-btn-icon-double-right,
.mx-btn.mx-btn-text.mx-btn-icon-double-left {
  display: none;
}
.mx-calendar.mx-calendar-panel-date {
  width: 100%;
}
.mx-calendar-header-label {
  button {
    color: #010d35;
    font-weight: 600;
    font-family: 'Commissioner';
    font-size: 20px;
  }
}
.mx-table th {
  color: #010d35;
  font-family: 'Commissioner';
  font-size: 15px;
  font-weight: 400;
}
.mx-calendar-content .cell {
  color: #010d35;
  font-family: 'Commissioner';
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  width: 47px;
  height: 36px;
}
.mx-table-date .cell.not-current-month {
  color: #80869a;
  font-family: 'Commissioner';
  font-size: 16px;
  font-weight: 500;
}
.mx-calendar-content .cell:hover,
.mx-calendar-content .cell.active,
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  background: #f8f9fe;
  color: #6764ff;
}
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  border-radius: 0;
}
.mx-calendar-content .cell.active {
  border-radius: 12px 0 0 12px;
}
.cell.not-current-month.disabled:hover {
  background: none;
  color: #80869a;
}
.mx-calendar-content {
  height: auto;
}
.cell.in-range ~ .cell.active {
  border-radius: 0 12px 12px 0 !important;
}
.mx-calendar-content .cell.disabled {
  color: #ccc !important;
  background: none;
  opacity: 0.5;
}
.mx-calendar-content .cell.disabled:hover {
  color: #ccc;
}
</style>
