<template>
  <div>
    <table class="VTippingTransactionsTable">
      <thead>
        <tr>
          <th
            valign="top"
            align="left"
          >
            <p>Ресторан</p>
            <span>{{ restName }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Стол</p>
            <span>{{ tableNumber }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>№Заказа B2P</p>
            <span class="B2P">{{ orderId }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Сумма чаевых</p>
            <span>{{ tips }} &#8381;</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Официант</p>
            <span>Константин Константинопольский</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Комментарий</p>
            <span class="message">{{ errorMessage }}</span>
          </th>
          <th
            valign="bottom"
            align="right"
          >
            <button class="pushButton">
              Протолкнуть
            </button>
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VTippingTransactionsTable',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    restName() {
      return this.item?.shop_name || ''
    },
    tableNumber() {
      return this.item?.table || ''
    },
    orderId() {
      return this.item?.originator_id || ''
    },
    date() {
      return this.item?.created_at || ''
    },
    tips() {
      return this.getFullAmount(this.item?.tips) || ''
    },
    errorMessage() {
      return this.item?.error_message || ''
    },
  },

  methods: {
    getFullAmount(total) {
      const number = parseFloat(total) / 100
      return number.toFixed(2)
    },
  },
}
</script>
<style lang="scss">
.VTippingTransactionsTable {
  padding: 14px 16px 14px 16px;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  position: relative;
  thead {
    tr {
      text-align: left;
      th {
        padding-bottom: 12px;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 7px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 14px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
  }
  .B2P {
    color: #6764ff;
  }
  .red {
    color: #ec4e4e;
  }
  .green {
    color: #25c26e;
  }
  .message {
    white-space: pre-wrap;
    max-width: 165px;
    display: block;
    color: red;
  }
  .pushButton {
    cursor: pointer;
    width: 102px;
    height: 30px;
    color: #fff;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
.errorButton {
  width: 102px;
  height: 30px;
  border: 1px solid #ec4e4e;
  border-radius: 6px;
  background: #fff6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ec4e4e;
    margin-left: 6px;
  }
}
.activeTable {
  padding: 14px 16px 32px 16px;
}
</style>
